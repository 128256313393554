import React, { useState, useEffect } from 'react';

import {
  Typography,
  Box,
  CircularProgress,
  Container,
} from '@material-ui/core/';

import { Rating } from '@material-ui/lab';
import { useIntl } from 'react-intl';

import { isBrowser } from '../../../../../utils';
import BaseHOC from '../../../../../components/BaseHOC';
import PrimaryButton from '../../../../../components/PrimaryButton';
import TextField from '../../../../../components/TextField';

import {
  feedbackBookingCustomer,
  getPublicShopDetails,
} from '../../../../../actions';

const ReviewBooking = ({
  location,
  dispatch,
  publicShopDetails,
  enqueueSnackbar,
}) => {
  const [token, setToken] = useState('');
  const [shopId, setShopId] = useState('');
  const [stars, setStars] = useState(5);
  const [feedback, setFeedback] = useState('');
  const [successfulFeedback, setSuccessfulFeedback] = useState(false);
  const [loader, setLoader] = useState(false);
  const [feedbackRequired, setFeedbackRequired] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    setStars(parseInt(urlParams.get('star'), 10) || 5);
    setShopId(urlParams.get('shopId'));
    setToken(urlParams.get('token'));
  }, [location]);

  useEffect(() => {
    if (isBrowser && shopId) {
      dispatch(getPublicShopDetails(shopId));
    }
  }, [shopId]);

  useEffect(() => {
    if (publicShopDetails) {
      setFeedbackRequired(publicShopDetails.only_reviews_with_feedback);
    }
  }, [publicShopDetails]);

  const { formatMessage: f } = useIntl();

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        pt={2}
        mx="auto"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" align="center">
          {successfulFeedback
            ? f({ id: 'pageReviewSuccess' })
            : f({ id: 'pageReviewThankyou' })}
        </Typography>
      </Box>
      {successfulFeedback ? null : (
        <Box
          display="flex"
          mt={2}
          mx="auto"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Box
            justifyContent="center"
            display="flex"
            component="fieldset"
            borderColor="transparent"
          >
            <Typography component="legend" align="center">
              {f({ id: 'pageReviewReview' })}
            </Typography>
            <Rating
              name="size-large"
              value={stars}
              size="large"
              onChange={(event, newValue) => {
                setStars(newValue);
              }}
            />
          </Box>
          <Box pt={3} width="100%" justifyContent="center" display="flex">
            <TextField
              required={feedbackRequired}
              id="feedback"
              name="feedback"
              variant="outlined"
              multiline
              rows="6"
              label={f({ id: 'pageReviewComment' })}
              fullWidth
              value={feedback}
              onChange={(event) => setFeedback(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
      )}
      {loader ? <CircularProgress /> : null}
      {loader || successfulFeedback ? null : (
        <>
          <Box
            display="flex"
            mt={3}
            mx="auto"
            justifyContent="center"
            alignItems="center"
          >
            <Box>
              <PrimaryButton
                onClick={() => {
                  if (feedbackRequired && !feedback) {
                    enqueueSnackbar(f({ id: 'pageReviewFeedbackRequired' }), {
                      variant: 'warning',
                    });
                    return;
                  }
                  dispatch(
                    feedbackBookingCustomer(
                      shopId,
                      token,
                      feedback,
                      stars,
                      enqueueSnackbar,
                    ),
                  )
                    .then((x) => {
                      setLoader(false);
                      setSuccessfulFeedback(true);
                    })
                    .catch((error) => {
                      setLoader(false);
                    });
                  setLoader(true);
                }}
              >
                {f({ id: 'pageReviewSubmit' })}
              </PrimaryButton>
            </Box>
          </Box>
        </>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  publicShopDetails: state.publicShopDetails,
});

export default BaseHOC(ReviewBooking, null, mapStateToProps);
